<template>
  <div class="bg-blue-50 h-full">
    <div class="flex justify-center items-center gap-6">
      <button @click="$store.dispatch('changeMode')" class="bg-blue-400 h-fit text-white p-2 hover:bg-blue-500">
        {{ $store.getters.getMode }}
      </button>
      <ton-connect/>
    </div>
    <HomeView/>
  </div>

</template>

<script>
import TonConnect from "@/components/TonConnect.vue";

import {mapGetters} from "vuex";
import HomeView from "@/views/HomeView.vue";

export default {
  name: 'App',
  components: {
    HomeView,
    TonConnect,
  },
  data() {
    return {}
  },
  methods: {
    async afterMount() {
      await this.setEndpoint();
    },
  },
  computed: {
    ...mapGetters(['getMode']),
  },
  watch: {
    getMode(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setEndpoint()
      }
    }
  },
  mounted() {
    this.afterMount()
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.no-select {
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

html, body {
  margin: 0;
  padding: 0
}
</style>
