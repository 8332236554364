import { createApp } from 'vue'
import App from './App.vue'
import './assets/main.css';
import router from './router'
import store from './store'
import globalMixin from './mixins/globalMixin';
const app = createApp(App);

app.mixin(globalMixin);
app.use(router);
app.use(store);
app.mount('#app');