import {Address, TonClient4} from "@ton/ton";
import {getHttpV4Endpoint} from "@orbs-network/ton-access";

export default {
    methods: {
        async runContractMethod(address, method, args) {
            const endpoint = this.$store.getters.getEndpoint
            const client = new TonClient4({
                endpoint
            });
            let lastBlock = await this.getLastBlock()
            const result = await client.runMethod(
                lastBlock.last.seqno,
                Address.parse(address),
                method,
                args
            );
            return result;
        },

        async getLastBlock() {
            let endpoint = this.$store.getters.getEndpoint
            if (endpoint == null) await this.setEndpoint();
            endpoint = this.$store.getters.getEndpoint
            const client = new TonClient4({
                endpoint
            });
            let lastBlock = await client.getLastBlock();
            let seqno = lastBlock.last.seqno;
            this.$store.dispatch('setSeqno', seqno)
            return lastBlock;
        },
        async setEndpoint() {
            const endpoint = await getHttpV4Endpoint({
                network: this.$store.getters.getMode,
            });
            this.$store.dispatch('setEndpoint', endpoint)
            return endpoint
        },
    },
    mounted() {
        this.$watch(
            () => this.$store.getters.getMode,
            (newValue, oldValue) => {
                console.log('mode changed',oldValue,newValue)
                this.setEndpoint()
            }
        );
    }
};