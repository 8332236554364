<template>
  <transition-group>
    <div v-if="isBucket" id="overlay" @click="isBucket = false"
         class="bg-gray-800 bg-opacity-60 h-full w-full fixed top-0 z-40"></div>

    <!-- Всплывающее окно -->
    <div v-if="isBucket" class="fixed inset-0 z-50 flex justify-center items-center pointer-events-none">
      <div class="p-4 bg-white rounded shadow-lg pointer-events-auto select-none w-5/6 max-w-lg relative">
        <h1 class="text-left mb-1 pl-0">Bucket</h1>
        <button
            class="hover:text-gray-800 py-1 pr-1 text-gray-700 absolute top-4 right-4 text-sm w-min"
            @click="isBucket = false">✖
        </button>
        <div class="alert-info text-sm" v-if="bucket.length > 0">
          <span>📋</span><span>You can send up to <b>{{ $store.getters.getMaxMessages }} txs</b> at once.<br>The earliest will be processed first.</span>
        </div>
        <div class="alert-danger text-sm mt-2" v-if="bucket.length > 0">
          <span>🤷‍♀️</span><span><b>Attention!</b> Bucket is an experimental thing. #DYOR #NFA</span>
        </div>
        <p v-if="bucket.length === 0" class="p-5">¯\_(ツ)_/¯</p>
        <div v-for="(item, index) in bucket" :key="index" class="flex justify-around py-2 border-b">
          <span class="text-left">{{ this.forms[item.formId].name }}</span>
          <span class="flex-grow border-dotted border-b-3 border-black mx-2"></span>
          <span class="flex justify-end gap-1">
            <input type="number" v-model="bucket[index].times" step="1" min="1"
                   class="border border-gray-300 px-1 rounded focus:outline-none focus:ring focus:border-purple-100 w-fit"
                   style="width: 55px;"
                   placeholder="times" required>
                        <button
                            class="hover:text-gray-800 pl-2 pr-1 text-gray-700 text-sm w-min"
                            @click="bucket.splice(index,1)">✖
            </button>
          </span>
        </div>
        <div class="grid gap-4 grid-cols-3 lg:grid-cols-5 mt-2" v-if="bucket.length > 0">
          <div></div>
          <div class="hidden lg:block"></div>
          <div class="hidden lg:block"></div>
          <button type="button" @click="bucket = []; isBucket = false"
                  class="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600">Dump
          </button>
          <button type="button" @click="sendBucket()"
                  class="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600">Send
          </button>
        </div>
      </div>
    </div>
    <div class="fixed bottom-4 right-4 z-40" v-if="!isBucket">
      <button @click="openBucket()"
              class="bg-purple-500 text-white p-2 px-4 font-bold rounded hover:bg-purple-600 h-fit shadow-lg">🗑
        Bucket{{ bucketCount }}
      </button>
    </div>
  </transition-group>

  <div id="main" class="pb-16 pl-4 pr-4 bg-blue-50">
    <div class="container mx-auto grid grid-cols-1 lg:grid-cols-2">
      <div>
        <div class="container mx-auto grid grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
          <div class="alert-info">
            <span>📋</span><span><b>Hint:</b> use <b>amount(), toNano(N)</b> in storeCoins, <b>now()</b> in storeUint and <b>myAddress()</b> in storeAddress.</span>
          </div>
          <div class="alert-danger">
            <span>🛸</span><span><b>Attention:</b> use <b>act</b> on your own risk and only for testing purposes.</span>
          </div>
        </div>
        <div class="container h-fit mx-auto grid grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
          <button @click="addForm()" class="bg-purple-500 text-white p-2 rounded hover:bg-purple-600">+ Action</button>
          <button @click="saveForms()" class="bg-green-500 text-white p-2 rounded hover:bg-green-600">Save all</button>
          <button @click="resetAll()" class="bg-red-500 text-white p-2 rounded hover:bg-red-600">Reset all</button>
          <button @click="show_templates = !show_templates"
                  class="bg-yellow-500 col-span-1 text-white p-2 rounded hover:bg-yellow-600">Templates <span
              v-if="show_templates">▲</span> <span v-else>▼</span>
          </button>
        </div>
        <div v-show="show_templates" class="container h-fit mx-auto grid grid-cols-2 lg:grid-cols-4 gap-4 mb-4">

          <button @click="addForm('nftTransfer')" class="bg-indigo-500 text-sm text-white p-2 rounded hover:bg-indigo-600 flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/punk.svg')" alt="" class="no-select h-4"> <span>Send NFT</span>
          </button>
          <button @click="addForm('jettonTransfer')" class="bg-slate-700 text-sm text-white p-2 rounded hover:bg-slate-800 flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/bolt.svg')" alt="" class="no-select h-4"> <span>Send Jetton</span>
          </button>
          <button @click="addForm('dedustSwap')" class="bg-orange-500 text-sm text-white p-2 rounded hover:bg-orange-600 flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/dedust.svg')" alt="" class="no-select h-4"> <span>TON to DUST</span>
          </button>
          <button @click="addForm('dedustSwapScaleTon')"
                  class="bg-orange-500 text-white p-2 rounded hover:bg-orange-600 text-sm flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/dedust.svg')" alt="" class="no-select h-4"> <span>DUST to TON</span>
          </button>
          <button @click="addForm('dedustSwapTonPunk')"
                  class="bg-orange-500 text-white p-2 rounded hover:bg-orange-600 text-sm flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/dedust.svg')" alt="" class="no-select h-4"> <span>TON to PUNK</span>
          </button>
          <button @click="addForm('dedustSwapPunkTon')"
                  class="bg-orange-500 text-white p-2 rounded hover:bg-orange-600 text-sm flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/dedust.svg')" alt="" class="no-select h-4"> <span>PUNK to TON</span>
          </button>
          <button @click="addForm('stonSwapTONPunk')"
                  class="bg-stone-700 text-white p-2 rounded hover:bg-stone-800 text-sm flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/ston.svg')" alt="" class="no-select h-4"> <span>TON to PUNK</span>
          </button>
          <button @click="addForm('stonSwapPunkTON')"
                  class="bg-stone-700 text-white p-2 rounded hover:bg-stone-800 text-sm flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/ston.svg')" alt="" class="no-select h-4"> <span>PUNK to TON</span>
          </button>
          <button @click="addForm('stonSwapTONSpng')"
                  class="bg-stone-700 text-white p-2 rounded hover:bg-stone-800 text-sm flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/ston.svg')" alt="" class="no-select h-4"> <span>TON to SPNG</span>
          </button>
          <button @click="addForm('stonSwapSpngTON')"
                  class="bg-stone-700 text-white p-2 rounded hover:bg-stone-800 text-sm flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/ston.svg')" alt="" class="no-select h-4"> <span>SPNG to TON</span>
          </button>
          <button @click="addForm('BigPumpBuy')"
                  class="bg-gradient-to-r from-cyan-500 to-blue-500 text-white p-2 text-sm rounded hover:from-blue-500 hover:to-cyan-500 flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/w95.svg')" alt="" class="no-select h-5"> <span>Buy on BigBump</span>
          </button>
          <button @click="addForm('BigPumpSell')"
                  class="bg-gradient-to-r from-cyan-500 to-blue-500 text-white p-2 text-sm rounded hover:from-blue-500 hover:to-cyan-500 flex justify-center gap-2 items-center">
            <img :src="require('@/assets/svg/w95.svg')" alt="" class="no-select h-5"> <span>Sell on BigPump</span>
          </button>
        </div>
      </div>
      <div class="hidden lg:flex lg:flex-col justify-center items-center">
        <span>sub <a href="https://t.me/ghwnd" class="underline text-pink-700" target="_blank">@ghwnd</a> or not idk</span>
        <div>also visit <a href="https://t.me/tondev" class="underline text-pink-700" target="_blank">@tondev</a> <a href="https://t.me/tondev_eng" class="underline text-pink-700" target="_blank">@tondev_eng</a> <a href="https://t.me/tactlang" class="underline text-pink-700" target="_blank">@tactlang</a></div>
      </div>
    </div>
    <div class="container mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-6">

      <div v-for="(form,i) in forms" :key="i" class="bg-white p-4 rounded shadow relative h-fit">
        <div class="manage grid grid-cols-4 lg:grid-cols-12 top-3 right-3 gap-4" :class="form.show ? 'lg:mb-6 mb-2' : ''">
          <h3 class="lg:text-left text-md order-last lg:order-first col-span-4 lg:col-span-8 font-semibold">Action: {{ form.name }}</h3>
          <div class="col-span-4 grid grid-cols-4">
            <icon-hint icon_pos="relative" @click="forms[i].show = !forms[i].show" pos="top" :icon="form.show ? 'svg/minimize.svg' : 'svg/maximize.svg'">{{ form.show ? 'Hide form' : 'Show form' }}</icon-hint>
            <icon-hint icon_pos="relative" @click="renameForm(i)" pos="top" icon="svg/edit.svg">Edit title</icon-hint>
            <icon-hint icon_pos="relative" @click="addForm(i,true)" pos="top" icon="svg/duplicate.svg">Duplciate form</icon-hint>
            <icon-hint icon_pos="relative" @click="delForm(i)" pos="top" icon="svg/close.svg">Close form</icon-hint>
          </div>
        </div>
        <form @submit.prevent="sendForm(i)" v-show="form.show">

          <div v-if="form.read_before !== undefined" class="alert-danger mb-2"><span>🧐</span><span><b>Attention:</b> {{form.read_before}}</span></div>
          <div v-if="typeof(form.type) !== 'undefined' && ['dedust_swap_from_j', 'transfer_j'].includes(form.type)"
               class="grid col-span-10 lg:grid-cols-12 mb-2">
            <div class="grid grid-cols-9 lg:grid-cols-7 lg:col-span-7 col-span-10">
              <input
                  v-model="forms[i].jetton" type="text"
                  class="border border-gray-300 lg:col-span-6 col-span-8 p-2  rounded focus:outline-none focus:ring focus:border-purple-100"
                  placeholder="Jetton master"
                  required>
              <icon-hint icon_pos="relative" pos="left">Jetton master (jetton address)</icon-hint>
            </div>
            <div class="grid grid-cols-10 col-span-10 lg:grid-cols-5 lg:col-span-5 mt-2 lg:mt-0">
              <button
                  class="bg-emerald-500 col-span-9 lg:col-span-4 text-white p-2 rounded hover:bg-emerald-600 lg:mb-0"
                  type="button" @click="fetchUserJettonWalletAddress(form.jetton,i)">Get my jetton wallet
              </button>
              <icon-hint icon_pos="relative" pos="left">Get your jetton wallet address</icon-hint>
            </div>
          </div>
          <div class="grid mb-2 grid-cols-10 lg:grid-cols-12">
            <input type="text" v-model="forms[i].address"
                   class="border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-purple-100"
                   :class="typeof(form.address_hint) !== 'undefined' ? 'col-span-9 lg:col-span-11' : 'col-span-10 lg:col-span-12'"
                   placeholder="Contract Address"
                   required>
            <icon-hint icon_pos="relative" pos="left" v-if="typeof(form.address_hint) !== 'undefined'">{{ form.address_hint }}</icon-hint>
          </div>
          <div class="grid mb-2 grid-cols-10 lg:grid-cols-12">
            <input type="number" v-model="forms[i].amount" step="0.000000001"
                   class="border border-gray-300 p-2 rounded col-span-9 lg:col-span-11 focus:outline-none focus:ring focus:border-purple-100"
                   placeholder="Amount (in TON)" required>
            <span class="p-1 pt-2 lg:p-2 col-span-1 lg:col-span-1 font-bold no-select">💎</span>
          </div>
          <div class="w-full flex justify-center">
            <h3 class="font-bold rounded p-2 border-fuchsia-800 bg-fuchsia-400 mb-2 text-white w-full"
                v-if="Object.keys(forms[i].cells).length > 0">Cells</h3></div>
          <div v-for="(cell,name) in forms[i].cells" class="relative" :key="name">
            <div class="manage grid grid-cols-10 lg:grid-cols-12 gap-4 mb-2">
              <span
                  class="cellName col-span-6 p-2 pl-3 pr-3 lg:col-span-8 text-sm w-fit bg-purple-500 border-purple-800 rounded text-white font-bold relative">Cell: {{
                  name
                }}
              </span>
              <icon-hint icon_pos="relative" @click="hideCell(i,name)" pos="top" :icon="cell.minimized === true ? 'svg/maximize.svg' : 'svg/minimize.svg'">{{ cell.minimized === true ? 'Show cell' : 'Hide cell' }}</icon-hint>
              <icon-hint icon_pos="relative" @click="renameCell(i,name)" pos="top" icon="svg/edit.svg">Edit title</icon-hint>
              <icon-hint icon_pos="relative" @click="cloneCell(i, name)" pos="top" icon="svg/duplicate.svg">Duplicate cell</icon-hint>
              <icon-hint icon_pos="relative" @click="delCell(i,name)" pos="top" icon="svg/close.svg">Remove cell</icon-hint>
            </div>

            <div v-show="cell.minimized !== true">
            <div
                class="grid grid-cols-3 md:grid-cols-3 mb-2 gap-4 bg-purple-50 rounded-md p-1 border-2 border-purple-100 relative">
              <div></div>
              <div></div>
              <button type="button" @click="addLine(i, name)"
                      class="bg-green-500 text-white p-2 rounded hover:bg-green-600">+store
              </button>

            </div>
            <div v-for="(line,j) in cell.lines" :key="j"
                 class="grid grid-cols-3 md:grid-cols-6 mb-2 gap-4 bg-purple-50 rounded-md p-1 border-2 border-purple-100 relative">
              <div class="linetype flex justify-center items-center col-span-2 relative">
                <button v-if="j != 0"
                        type="button"
                        class="bg-red-500 text-white pl-3 pr-3 p-1 rounded hover:bg-red-600 absolute left-1.5"
                        @click="removeLine(i,name,j)">✖
                </button>
                <span
                    v-if="j == 0 || (typeof(cell[j-1]) !== 'undefined' && cell[j-1].type === 'endCell')">beginCell()</span>.{{
                  line.type
                }}<span
                  v-if="line.type === 'endCell'">()</span>
                <icon-hint icon_pos="absolute" pos="top" v-if="typeof(line.hint) !== 'undefined'">{{ line.hint }}</icon-hint>

              </div>
              <select v-if="line.type !== 'endCell'"
                      class="border border-gray-300 p-2 rounded col-span-1 focus:outline-none focus:ring focus:border-purple-100"
                      v-model="forms[i].cells[name].lines[j].subType" :name="'line_select_' + j">
                <option selected value="Uint">Uint</option>
                <option value="Address">Address</option>
                <option value="Coins">Coins</option>
                <option value="Bit">Bit</option>
                <option value="Int">Int</option>
                <option value="StringTail">StringTail</option>
                <option value="Ref">Ref</option>
                <option value="RefString">Ref (base64)</option>
              </select>
              <input v-if="line.type !== 'endCell' && line.subType !== 'Ref'"
                     v-model="forms[i].cells[name].lines[j].value" type="text"
                     class="border border-gray-300 p-2 rounded w-full focus:outline-none focus:ring focus:border-purple-100"
                     :class="['Int','Uint'].includes(line.subType) ? 'col-span-2' : 'col-span-3'" placeholder="value"
                     required>
              <input v-if="['Int','Uint'].includes(line.subType)" v-model="forms[i].cells[name].lines[j].bits"
                     type="number"
                     class="border border-gray-300 p-2 rounded w-full col-span-1 focus:outline-none focus:ring focus:border-purple-100"
                     placeholder="bits" required>
              <select v-if="line.subType === 'Ref'"
                      class="border border-gray-300 p-2 rounded col-span-3 focus:outline-none focus:ring focus:border-purple-100"
                      v-model="forms[i].cells[name].lines[j].value"
                      :name="'line_select_ref_' + j">
                <option v-for="(cell,cellName) in forms[i].cells" :key="cellName" :value="cellName">{{
                    cellName
                  }}
                </option>
              </select>
            </div>

            <div
                class="grid grid-cols-3 md:grid-cols-6 mb-2 gap-4 bg-purple-50 rounded-md p-1 border-2 border-purple-100 relative">
              <div class="linetype flex justify-center items-center col-span-2 relative">
                .endCell()
                <icon-hint @click="copyCell(i, name)" pos="top" icon="svg/copy.svg">Copy this cell (base64)</icon-hint>
              </div>
            </div>
          </div>
            <div class="container p-2 bg-red-400 border-red-600 rounded text-white mb-2 relative"
                 v-if="temp.copyCellErrors[i] && temp.copyCellErrors[i][name] && temp.copyCellErrors[i][name].length > 0">
              <div v-for="error in temp.copyCellErrors[i][name]" class="p-1 break-all text-left" :key="error">• {{
                  error
                }}
              </div>
              <button
                  class="bg-gray-600 hover:bg-gray-700 border-gray-800 rounded p-1 px-2 absolute right-2 top-2 text-white"
                  @click="delete(temp.copyCellErrors[i])">✖
              </button>
            </div>
          </div>
          <div
              v-if="ifCells(i)"
              class="grid gap-4 grid-cols-2 mb-2 gap-4 bg-purple-50 rounded-md p-1 border-2 border-purple-100 relative">
            <div class="p-2 no-select">Cell to send:</div>
            <select
                class="border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-purple-100"
                v-model="forms[i].cellToSend"
                :name="'line_cell_to_send'">
              <option v-for="(cell,name) in forms[i].cells" :key="name" :value="name">{{ name }}</option>
            </select>
          </div>
          <div class="container p-2 bg-red-400 border-red-600 rounded text-white mb-2 relative"
               v-if="form.errors && form.errors.length > 0">
            <div v-for="error in form.errors" class="p-1 break-all text-left" :key="error">• {{ error }}</div>
            <button
                class="bg-gray-600 hover:bg-gray-700 border-gray-800 rounded p-1 px-2 absolute right-2 top-2 text-white"
                @click="delete(forms[i].errors)">✖
            </button>
          </div>
          <div class="grid gap-4 grid-cols-3">
            <div v-if="typeof(form.doclink) === 'undefined'"></div>
            <a :href="form.doclink" target="_blank" v-if="typeof(form.doclink) !== 'undefined'"
               class="bg-purple-500 text-white p-2 rounded hover:bg-purple-600 select-none">Docs
            </a>
            <button type="button" @click="addLine(i, forms[i].cellCursor, 'store', 'Uint', true)"
                    class="bg-green-500 text-white p-2 rounded hover:bg-green-600">New cell
            </button>
            <div class="grid grid-cols-5 lg:grid-cols-4">
              <button type="submit" class="bg-blue-500 text-white p-2 col-span-3 rounded-l-md hover:bg-blue-600">Send
              </button>
              <button type="button" @click="toBucket(i)"
                      class="bg-purple-500 text-white p-2 col-span-2 lg:col-span-1 rounded-r-md font-bold hover:bg-purple-600">🗑
              </button>
            </div>
          </div>
        </form>
      </div>


    </div>


  </div>
</template>

<script>
/* eslint-disable */


import {Address, beginCell, Cell, contractAddress} from "@ton/ton";
import IconHint from "@/components/IconHint";
import templates from "@/templates/templates";

const math = require('mathjs');

export default {
  name: 'HomeView',
  components: {IconHint},
  data() {
    return {
      show_templates: false,
      templates: templates,
      forms: {
        0: {
          name: 'Send qq to sd',
          show: true,
          address: 'UQD_5Gn0KWVVr1nXWuNB_ZJlAWjAdI8LNqO_HTNH7ijeW_R_',
          amount: '1',
          cellToSend: 'textComment',
          cellCursor: 'textComment',
          typeCursor: null,
          cells: {
            "textComment": {
              minimized: false,
              locked: false,
              lines: {
                "0": {
                  "type": "store", "subType": "Uint", "value": "0", "bits": 32
                },
                "1": {
                  "type": "store",
                  "subType": "StringTail",
                  "value": "qq, sd",
                  'hint': 'Here: text comment, also known as "memo".'
                }
              }
            },
          }
        },
      },
      bucket: [],
      isBucket: false,
      temp: {
        errors: [],
        cells: {},
        copyCellErrors: {}
      }
    }
  },
  methods: {
    generateRandomHash(length = 16) {
      const array = new Uint8Array(length);
      window.crypto.getRandomValues(array);
      return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
    },
    toBucket(formId) {
      if (this.forms[formId].uniq === 'undefined') this.forms[formId].uniq = this.generateRandomHash()
      this.bucket.push({
        times: 1,
        formId: formId,
        uniq: this.forms[formId].uniq
      })
    },
    async copyCell(formId, cellName) {
      this.temp.cells = [];
      this.temp.errors = [];
      this.temp.copyCellErrors = [];
      let cell = this.buildCell(formId, cellName, true)
      if (this.temp.errors.length > 0) {
        this.temp.copyCellErrors[formId] = {}
        return this.temp.copyCellErrors[formId][cellName] = this.temp.errors;
      }
      const toCopy = cell.endCell().toBoc().toString("base64")
      return await navigator.clipboard.writeText(toCopy);
    },
    buildCell(formId, cellName, fromCopy = false) {
      let form = this.forms[formId]
      const amount = form['amount'] * 10 ** 9
      let cell = form.cells[cellName].lines
      console.log('cell', cell)
      let errors = [];
      let updatedExpression
      let buildedCell = beginCell();
      console.log(cellName)
      Object.keys(cell).forEach((id) => {
        const line = cell[id]
        if (line.type === 'store') {
          console.log(line.type, line.subType, line.value, line.bits)
          try {
            switch (line.subType) {
              case 'Address':
                let addr;
                addr = line.value
                if (line.value === 'myAddress()') addr = this.$store.getters.getUserAddress;
                try {
                  addr = Address.parse(addr)
                } catch (e) {
                  return errors.push('Invalid address: ' + line.value)
                }
                console.log(addr)
                return buildedCell.storeAddress(addr)
              case 'Uint':
                const now = Math.floor(Date.now() / 1000);
                updatedExpression = line.value.replace(/now\(\)/g, now);
                try {
                  const result = math.evaluate(updatedExpression);
                  console.log(result, parseInt(line.bits))
                  return buildedCell.storeUint(result, parseInt(line.bits))
                } catch (error) {
                  errors.push('Err in evaluate')
                  return null;
                }
              case 'StringTail':
                console.log(line.value)
                return buildedCell.storeStringTail(line.value)
              case 'Ref':
                if (line.value !== cellName && typeof (this.temp.cells[line.value]) !== 'undefined') {
                  console.log(this.temp.cells[line.value])
                  return buildedCell.storeRef(this.temp.cells[line.value])
                } else if (line.value !== cellName && typeof (this.temp.cells[line.value]) === 'undefined') {
                  let buildRefCell = this.buildCell(formId, line.value, true)
                  return buildedCell.storeRef(buildRefCell)
                } else return errors.push('Invalid ref: ' + line.value);
              case 'RefString':
                let cellFromB64
                try {
                  cellFromB64 = Cell.fromBase64(line.value)
                } catch (e) {
                  return errors.push('storeRef ' + e)
                }
                return buildedCell.storeRef(cellFromB64)
              case 'Coins':
                updatedExpression = line.value.replace(/amount\(\)/g, amount);
                const regex = /toNano\((\d+(\.\d+)?)\)/g;

                // Заменяем каждое вхождение toNano(N) на результат N * 1e9
                const replacedInput = updatedExpression.replace(regex, (match, p1) => {
                  return (parseFloat(p1) * 1e9).toString();
                });
                console.log(replacedInput)
                try {
                  const result = math.evaluate(replacedInput);
                  console.log(result)
                  return buildedCell.storeCoins(result)
                } catch (error) {
                  errors.push('Error in evaluate ' + line.value)
                  return null;
                }
              case 'Bit':
                console.log(parseInt(line.value))
                return buildedCell.storeBit(parseInt(line.value))
              case 'Int':
                console.log(parseInt(line.value), parseInt(line.bits))
                return buildedCell.storeInt(parseInt(line.value), parseInt(line.bits))
              default:
                return console.log('error', line.type);
            }
          } catch (e) {
            errors.push(e)
          }
        }
      })
      console.log('these errors', errors)
      this.temp.errors = this.temp.errors.concat(errors)
      this.temp.cells[cellName] = buildedCell
      return buildedCell;
    },
    async sendBucket() {
      this.temp.errors = [];
      this.temp.cells = [];
      this.temp.bucketErr = [];
      const tonConnectUI = this.$store.getters.getTonConnect
      if (!this.$store.getters.getConnectionStatus) {
        return tonConnectUI.openModal()
      }
      let messages = [];
      let counter_bucket_to_splice = 0
      let counter_txs = 0
      let reduce_bucket = {iterator: 0, count: 0}
      for (let j = 0; j < this.$store.getters.getMaxMessages; j++) {
        if (this.bucket[j]) {
          let i = this.bucket[j].formId;
          let form = this.forms[i]
          console.log('form', form)
          let cells = [];
          Object.keys(form.cells).forEach(name => {
            cells[name] = this.buildCell(i, name);
          })
          console.log('cells', cells)
          if (this.temp.errors.length > 0) {
            this.temp.bucketErr.push('Check ' + this.forms[i].name + ' form')
            return this.forms[i].errors = this.temp.errors;
          }
          let payload = null;
          if (typeof (cells[this.forms[i].cellToSend]) !== 'undefined') {
            const cellToSend = cells[this.forms[i].cellToSend].endCell();
            console.log('cellToSend:', cellToSend)
            payload = cellToSend.toBoc().toString("base64")
          }
          console.log('boc', payload)
          let message = {
            address: this.forms[i].address,
            amount: this.forms[i].amount * 1000000000
          }
          if (payload != null) message.payload = payload
          const remainingCapacity = this.$store.getters.getMaxMessages - counter_txs;
          const messagesToAdd = Math.min(this.bucket[j].times, remainingCapacity);

          messages.push(...Array(messagesToAdd).fill(message));

          let new_txs = messagesToAdd;
          counter_txs += new_txs
          if (new_txs < this.bucket[j].times) {
            reduce_bucket.iterator = j;
            reduce_bucket.count = new_txs;
            break;
          } else counter_bucket_to_splice++
        } else break;
      }
      console.log('messages', messages)
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 3600,
        messages: messages
      }
      console.log('transaction', transaction)
      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result)
        console.log('sent')
        console.log('bucky', this.bucket, reduce_bucket, reduce_bucket.iterator)
        this.bucket[reduce_bucket.iterator].times -= reduce_bucket.count
        this.bucket.splice(0, counter_bucket_to_splice)
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    async sendForm(i) {
      this.temp.errors = [];
      this.temp.cells = [];
      const tonConnectUI = this.$store.getters.getTonConnect
      if (!this.$store.getters.getConnectionStatus) {
        return tonConnectUI.openModal()
      }
      const form = this.forms[i]
      let cells = [];
      Object.keys(form.cells).forEach(name => {
        cells[name] = this.buildCell(i, name);
      })
      if (this.temp.errors.length > 0) {
        return this.forms[i].errors = this.temp.errors;
      }
      let payload = null;
      if (typeof (cells[this.forms[i].cellToSend]) !== 'undefined') {
        const cellToSend = cells[this.forms[i].cellToSend].endCell();
        console.log('cellToSend:', cellToSend)
        payload = cellToSend.toBoc().toString("base64")
      }
      console.log('boc', payload)
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 3600,
        messages: [
          {
            address: this.forms[i].address,
            amount: this.forms[i].amount * 1000000000,
            payload: payload
          }
        ]
      }
      try {
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log(result)
        console.log('sent')
      } catch (e) {
        console.log('discard')
        console.error(e);
      }
    },
    addLine(formId, cellName = 'body', type = 'store', subType = 'Uint', newCell = false) {
      if (this.forms[formId].typeCursor === 'endCell' || (newCell && Object.keys(this.forms[formId].cells).length > 0)) {
        let lastChar = this.forms[formId].cellCursor[this.forms[formId].cellCursor.length - 1];
        let n = 1;
        if (!isNaN(parseInt(lastChar))) n = parseInt(lastChar) + 1;
        cellName = 'body' + n;
      }
      this.forms[formId].cellCursor = cellName;
      this.forms[formId].typeCursor = type;
      if (typeof (this.forms[formId].cells[cellName]) === 'undefined') this.forms[formId].cells[cellName] = {lines: {}};
      return this.forms[formId].cells[cellName].lines[Object.entries(this.forms[formId].cells[cellName].lines).length] = {
        type: type,
        subType: subType,
        value: ''
      };
    },
    removeLine(formId, cellName, lineId) {
      delete (this.forms[formId].cells[cellName].lines[lineId])
      const values = Object.values(this.forms[formId].cells[cellName].lines);
      this.forms[formId].cells[cellName].lines = values.reduce((acc, item, index) => {
        acc[index] = item;
        return acc;
      }, {});
    },
    delForm(formId) {
      delete (this.forms[formId])
      const values = Object.values(this.forms);
      this.forms = values.reduce((acc, item, index) => {
        acc[index] = item;
        return acc;
      }, {});
      this.bucket = this.bucket.filter(element => {
        // Проверяем, совпадает ли uniq текущей формы с uniq элемента
        if (this.forms[element.formId]?.uniq !== element.uniq) {
          // Ищем форму с matching uniq
          const matchingForm = Object.entries(this.forms).find(([_, form]) =>
              form.uniq === element.uniq
          );

          if (matchingForm) {
            // Если нашли форму с таким же uniq, обновляем formId
            element.formId = matchingForm[0];
            return true;
          }

          // Если форма с таким uniq не найдена, удаляем элемент
          return false;
        }

        // Если uniq совпадает с текущей формой, оставляем элемент
        return true;
      });
    },
    addForm(template = 'default', fromExist = false) {
      let form
      if(!fromExist) form = JSON.parse(JSON.stringify(this.templates[template]))
      else form = JSON.parse(JSON.stringify(this.forms[template]))
      for (let key in form.cells) {
        const cell = form.cells[key];
        if (!cell.hasOwnProperty('lines')) {
          form.cells[key] = {lines: cell};
        }
      }
      form.uniq = this.generateRandomHash()
      this.forms[Object.keys(this.forms).length] = form
    },
    ifCells(formId) {
      return Object.keys(this.forms[formId].cells).length > 0;
    },
    saveForms() {
      const forms = JSON.stringify(this.forms)
      localStorage.setItem('actions', forms);
    },
    renameForm(i) {
      const name = prompt("Enter new name (1-32 chars):");
      if (name.length > 0 && name.length < 32) {
        this.forms[i].name = name;
      }
    },
    renameCell(formId, cellName) {
      const name = prompt("Enter new name (1-18 chars):");
      if (name.length > 0 && name.length < 18 && name !== cellName) {
        const cell = this.forms[formId].cells[cellName];
        this.forms[formId].cells[name] = cell
        this.delCell(formId, cellName)
      }
    },
    hideCell(formId,cellName) {
      this.forms[formId].cells[cellName].minimized = !this.forms[formId].cells[cellName].minimized
    },
    cloneCell(formId,cellName) {
      this.forms[formId].cells[cellName + '_' + this.generateRandomHash().substring(0,3)] = structuredClone(this.forms[formId].cells[cellName])
    },
    delCell(formId, cellName) {
      delete (this.forms[formId].cells[cellName]);
    },
    resetAll() {
      this.forms = {
        0: this.templates.default,
      }
      this.bucket = {}
    },
    async fetchUserJettonWalletAddress(jetton, formId) {
      const tonConnectUI = this.$store.getters.getTonConnect
      if (!this.$store.getters.getConnectionStatus) {
        return tonConnectUI.openModal()
      }
      if (jetton.length < 32) return alert('Enter jetton master address');
      try {
        const address = this.$store.getters.getUserAddress;
        if (address == null) {
          return null;
        }
        let result = await this.runContractMethod(
            jetton,
            'get_wallet_address',
            [{type: "slice", cell: beginCell().storeAddress(Address.parse(address)).endCell()}]
        );
        if (!result.result[0] || (result.result[0].type && result.result[0].type !== 'slice')) {
          throw new Error('Invalid jetton master');
        }
        const userWalletAddress = result.result[0].cell.asSlice().loadAddress().toString();
        this.forms[formId].address = userWalletAddress
      } catch (error) {
        alert(error)
      }
    },
    openBucket() {
      this.isBucket = true
    },
  },
  computed: {
    bucketCount() {
      let count = 0
      this.bucket.forEach(e => {
        count += e.times
      })
      return count > 0 ? ' (' + count + ')' : ''
    }
  },
  watch: {},
  mounted() {
    let saved = localStorage.getItem("actions")
    if (saved != null) {
      let data = JSON.parse(saved)
      for (let form in data) {
        if (data[form].uniq === undefined) data[form].uniq = this.generateRandomHash()
        for (let key in data[form].cells) {
          const cell = data[form].cells[key];
          if (!cell.hasOwnProperty('lines')) {
            data[form].cells[key] = {lines: cell};
          }
        }
      }
      console.log(data)
      this.forms = data;
    }
  },
}
</script>
<style>    .masonry {
  column-count: 2;
  column-gap: 1rem;
}

.masonry-item {
  break-inside: avoid;
  margin-bottom: 1rem;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>