<template>
  <div class="flex items-center justify-center"
        :class="[{ 'absolute right-0': icon_pos === 'absolute', 'relative': icon_pos === 'relative' }]"
  >
    <span
        @mouseenter="show = true"
        @mouseleave="show = false"
        class="cursor-pointer text-white text-sm "
        :class="[{ 'icon-copy': icon === 'svg/copy.svg' }]"
    >
      <img :src="require('@/assets/' + icon)" alt="" class="no-select h-4">
    </span>

    <transition name="fade">
      <div
          v-if="show"
          class="absolute z-10 w-40 p-2 text-sm text-white bg-gray-800 rounded shadow-lg no-select"
          :class="[{
            'bottom-full left-1/2 transform -translate-x-1/2 mb-1': pos === 'top',
            'right-full top-1/2 transform -translate-y-1/2 mr-1': pos === 'left',
           }]"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'info.svg'
    },
    pos:  {
      type: String,
      default: 'top'
    },
    icon_pos:  {
      type: String,
      default: 'absolute'
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.icon-copy:active {
  transform: scale(0.85)
}
</style>