<template>
  <div id="connect" class="flex justify-center m-6" ></div>
</template>

<script>


export default {
  name: 'TonConnect',
  methods: {
    async connectDefine() {
      let lang = localStorage.getItem("lang")
      if(lang == null) lang = 'en'
      this.$store.dispatch('setTonConnect', lang)
      const tonConnectUI = this.$store.getters.getTonConnect;
      tonConnectUI.onStatusChange(async wallet => {
        if (wallet) {
          this.$store.dispatch('setConnectionStatus', true)
          this.$store.dispatch('setUserAddress', wallet.account.address)
          const currentWallet = tonConnectUI.wallet;
          this.$store.dispatch('setMaxMessages',currentWallet.device.features[1].maxMessages)
        } else {
          this.$store.dispatch('setConnectionStatus', false)
          this.$store.dispatch('setUserAddress', null)
        }
      });
    }
  },
  mounted() {
    this.connectDefine();
  }
}
</script>