import { createStore } from 'vuex'
import {THEME, TonConnectUI} from '@tonconnect/ui';

export default createStore({
  state: {
    TonConnect: null,
    mode: 'mainnet',
    contract: null,
    endpoint: null,
    isConnected: false,
    maxMessages: 0,
    user: {
      address: null,
    },
    seqno: 0
  },
  getters: {
    getTonConnect: (state) => {
      return state.TonConnect;
    },
    getAdder: (state) => {
      return state.mode === 'testnet' ? 'testnet.' : '';
    },
    getMode: (state) => {
      return state.mode;
    },
    getConnectionStatus: (state) => {
      return state.isConnected;
    },
    getContract: (state) => {
      return state.contract;
    },
    getUserAddress: (state) => {
      return state.user.address;
    },
    getEndpoint: (state) => {
      return state.endpoint;
    },
    getMaxMessages: (state) => {
      return state.maxMessages;
    },
  },
  mutations: {
    SET_TON_CONNECT(state, lang) {
      state.TonConnect = new TonConnectUI({
        manifestUrl: 'https://act.ghwnd.cc/tonconnect-manifest.json',
        buttonRootId: 'connect',
        language: lang,
        uiPreferences: {
            borderRadius: "none",
            theme: THEME.LIGHT,
         },
          actionsConfiguration: {
              skipRedirectToWallet: 'always'
          }
    }); 
    },
    SET_CONNECTION_STATUS(state, value) {
      state.isConnected = value
    },
    SET_USER_ADDRESS(state, address) {
      state.user.address = address
    },
    SET_ENDPOINT(state, v) {
      state.endpoint = v
    },
    SET_SEQNO(state, v) {
      state.seqno = v
    },
    SET_MAX_MESSAGES(state, v) {
      state.maxMessages = v
    },
    CHANGE_MODE(state) {
      state.mode = state.mode === 'mainnet' ? 'testnet' : 'mainnet';
    },
  },
  actions: {
    setTonConnect({ commit }, value) {
      commit("SET_TON_CONNECT", value);
    },
    setConnectionStatus({ commit }, value) {
      commit("SET_CONNECTION_STATUS", value);
    },
    setUserAddress({ commit }, address) {
      commit("SET_USER_ADDRESS", address);
    },
    setEndpoint({ commit }, v) {
      commit("SET_ENDPOINT", v);
    },
    setSeqno({ commit }, v) {
      commit("SET_SEQNO", v);
    },
    setMaxMessages({ commit }, v) {
      commit("SET_MAX_MESSAGES", v);
    },
    changeMode({ commit }) {
      commit("CHANGE_MODE");
    },
  },
  modules: {
  }
})
